var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"overview-block"},[_c('div',{staticClass:"flex gap-[25px]"},[_c('StatisticBlock',{attrs:{"title":"會員總數","value":_vm.chartData.totalMemberCount !== null
          ? `${_vm.truncateDecimal(_vm.chartData.totalMemberCount)}人`
          : '-'}}),_c('StatisticBlock',{attrs:{"title":"新會員總數","value":_vm.chartData.memberCountOfLastMonth !== null
          ? `${_vm.truncateDecimal(_vm.chartData.memberCountOfLastMonth)}人`
          : '-',"hint":"所有分店30日內完成註冊的會員數"}})],1),_c('div',{staticClass:"flex gap-[25px]"},[_c('StatisticBlock',{attrs:{"title":"預約回購週期","value":_vm.chartData.reserveRepurchaseCycle !== null
          ? `${_vm.truncateDecimal(_vm.chartData.reserveRepurchaseCycle)}天`
          : '-',"hint":"所有分店365天內有超過1次以上預約紀錄者，計算最後兩次預約相差時間的平均週期"}}),_c('StatisticBlock',{attrs:{"title":"電商回購週期","value":_vm.chartData.ecommerceRepurchaseCycle !== null
          ? `${_vm.truncateDecimal(_vm.chartData.ecommerceRepurchaseCycle)}天`
          : '-',"hint":"所有分店365天內有超過1次以上電商消費紀錄者，計算最後兩次消費相差時間的平均週期"}}),_c('StatisticBlock',{attrs:{"title":"銷售回購週期","value":_vm.chartData.salesRepurchaseCycle !== null
          ? `${_vm.truncateDecimal(_vm.chartData.salesRepurchaseCycle)}天`
          : '-',"hint":"所有分店365天內有超過1次以上有銷售消費紀錄者，計算最後兩次消費相差時間的平均週期"}}),_c('StatisticBlock',{attrs:{"title":"會員商城回購週期","value":_vm.chartData.memberShopRepurchaseCycle !== null
          ? `${_vm.truncateDecimal(_vm.chartData.memberShopRepurchaseCycle)}天`
          : '-',"hint":"所有分店365天內有超過1次以上有會員商城消費紀錄者，計算最後兩次消費相差時間的平均週期"}}),_c('StatisticBlock',{attrs:{"title":"第三方回購週期","value":_vm.chartData.externalRepurchaseCycle !== null
          ? `${_vm.truncateDecimal(_vm.chartData.externalRepurchaseCycle)}天`
          : '-',"hint":"所有分店365天內有超過1次以上有第三方系統消費紀錄者，計算最後兩次消費相差時間的平均週期"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }