<template>
  <div class="member-count-statistics">
    <ChartTitle
      title="會員人數趨勢"
      :useRangeType="true"
      :getChart="getChart"
    />
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import store from '@/store'
import { useChart } from '@/use/dashboard'
import { generateMemberCountConfig, getMemberCountData } from '../../common'
import { useOrg } from '@/use/useOrg'

export default defineComponent({
  name: 'MemberCountStatistics',
  components: { ChartTitle },
  setup () {
    const { chartRef, updateChart } = useChart(generateMemberCountConfig())
    const { orgId } = useOrg()

    const getChart = async ({ interval, splitType }) => {
      const newOptions = await getMemberCountData({
        orgId: orgId.value,
        interval,
        splitType,
      })

      updateChart(newOptions)
    }

    return {
      chartRef,
      getChart,
    }
  },
})
</script>

<style lang="postcss" scoped>
.member-count-statistics {
  @apply flex flex-col gap-5;
}
</style>
