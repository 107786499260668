<template>
  <div class="overview-block">
    <div class="flex gap-[25px]">
      <StatisticBlock
        title="會員總數"
        :value="
          chartData.totalMemberCount !== null
            ? `${truncateDecimal(chartData.totalMemberCount)}人`
            : '-'
        "
      />
      <StatisticBlock
        title="新會員總數"
        :value="
          chartData.memberCountOfLastMonth !== null
            ? `${truncateDecimal(chartData.memberCountOfLastMonth)}人`
            : '-'
        "
        hint="所有分店30日內完成註冊的會員數"
      />
    </div>
    <div class="flex gap-[25px]">
      <StatisticBlock
        title="預約回購週期"
        :value="
          chartData.reserveRepurchaseCycle !== null
            ? `${truncateDecimal(chartData.reserveRepurchaseCycle)}天`
            : '-'
        "
        hint="所有分店365天內有超過1次以上預約紀錄者，計算最後兩次預約相差時間的平均週期"
      />
      <StatisticBlock
        title="電商回購週期"
        :value="
          chartData.ecommerceRepurchaseCycle !== null
            ? `${truncateDecimal(chartData.ecommerceRepurchaseCycle)}天`
            : '-'
        "
        hint="所有分店365天內有超過1次以上電商消費紀錄者，計算最後兩次消費相差時間的平均週期"
      />
      <StatisticBlock
        title="銷售回購週期"
        :value="
          chartData.salesRepurchaseCycle !== null
            ? `${truncateDecimal(chartData.salesRepurchaseCycle)}天`
            : '-'
        "
        hint="所有分店365天內有超過1次以上有銷售消費紀錄者，計算最後兩次消費相差時間的平均週期"
      />
      <StatisticBlock
        title="會員商城回購週期"
        :value="
          chartData.memberShopRepurchaseCycle !== null
            ? `${truncateDecimal(chartData.memberShopRepurchaseCycle)}天`
            : '-'
        "
        hint="所有分店365天內有超過1次以上有會員商城消費紀錄者，計算最後兩次消費相差時間的平均週期"
      />
      <StatisticBlock
        title="第三方回購週期"
        :value="
          chartData.externalRepurchaseCycle !== null
            ? `${truncateDecimal(chartData.externalRepurchaseCycle)}天`
            : '-'
        "
        hint="所有分店365天內有超過1次以上有第三方系統消費紀錄者，計算最後兩次消費相差時間的平均週期"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted, computed } from 'vue'
import dayjs from '@/lib/dayjs'
import store from '@/store'
import StatisticBlock from '@/components/Dashboard/StatisticBlock.vue'
import { truncateDecimal, getOrgChartData } from '@/utils/dashboard'
import { useOrg } from '@/use/useOrg'

export default defineComponent({
  name: 'OverviewBlock',
  components: { StatisticBlock },
  emits: ['getTime'],
  setup (props, { emit }) {
    const { orgId } = useOrg()

    const chartData = reactive({
      totalMemberCount: null,
      memberCountOfLastMonth: null,
      reserveRepurchaseCycle: null,
      ecommerceRepurchaseCycle: null,
      salesRepurchaseCycle: null,
      memberShopRepurchaseCycle: null,
      externalRepurchaseCycle: null,
    })

    const getTotalMemberCount = async () => {
      const { result, lastUpdatedAt } = await getOrgChartData({
        orgId: orgId.value,
        key: 'orgMemberCount',
      })
      if (result) {
        chartData.totalMemberCount = result[0].count
      }
      emit('getTime', lastUpdatedAt)
    }

    const getMemberCountOfLastMonth = async () => {
      const { result } = await getOrgChartData({
        orgId: orgId.value,
        key: 'orgMemberCount',
        createdAtStart: dayjs().subtract(30, 'day').format('YYYY/MM/DD'),
        createdAtEnd: dayjs().format('YYYY/MM/DD'),
      })
      console.log('getMemberCountOfLastMonth', result)
      if (result) {
        chartData.memberCountOfLastMonth = result[0].count
      }
    }

    const getTransactionRepurchaseCycle = async () => {
      const { result } = await getOrgChartData({
        orgId: orgId.value,
        key: 'orgTransactionRepurchaseCycle',
      })
      if (result) {
        chartData.reserveRepurchaseCycle =
          result.find(({ type }) => type === '預約訂單')?.avgRepurchase || null
        chartData.ecommerceRepurchaseCycle =
          result.find(({ type }) => type === '電商訂單')?.avgRepurchase || null
        chartData.salesRepurchaseCycle =
          result.find(({ type }) => type === '銷售紀錄')?.avgRepurchase || null
        chartData.memberShopRepurchaseCycle =
          result.find(({ type }) => type === '會員商城')?.avgRepurchase || null
        chartData.externalRepurchaseCycle =
          result.find(({ type }) => type === '第三方系統')?.avgRepurchase || null
      }
    }

    onMounted(async () => {
      await Promise.allSettled([
        getTotalMemberCount(),
        getMemberCountOfLastMonth(),
        getTransactionRepurchaseCycle(),
      ])
    })

    return {
      chartData,
      truncateDecimal,
    }
  },
})
</script>

<style lang="postcss" scoped>
.overview-block {
  @apply flex flex-col gap-10;
}
</style>
