<template>
  <div class="ecommerce-statistics">
    <ChartTitle
      title="電商 首購 vs 回購人數趨勢"
      hint="電商首購客人數：第一次消費且過去沒有任何電商消費紀錄者，電商回購客人數：過去365天內有1次以上的電商消費紀錄者"
      :useRangeType="true"
      :getChart="getChart"
      @shopChange="onShopChange"
    />
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import store from '@/store'
import { useChart } from '@/use/dashboard'
import { generateFirstPurchaseConfig, getFirstPurchaseData } from '../../common'
import { useShop } from '@/use/shop'
import { useOrg } from '@/use/useOrg'
import { getAgeDataByShop } from '@/views/OrgOperateDashboard/MemberSummary/common'
import { cloneDeep, values, get } from 'lodash'

export default defineComponent({
  name: 'EcommerceStatistics',
  components: { ChartTitle },
  setup () {
    const { chartRef, updateChart } = useChart(generateFirstPurchaseConfig())
    const { shopId } = useShop()
    const { orgId } = useOrg()

    const baseChartOptions = ref({})
    const shopsData = ref({})
    const displayChartOptions = ref({})
    const chartParams = reactive({
      interval: 'month',
      splitType: 'day',
    })

    const refreshChartData = () => {
      const newOptions = cloneDeep(baseChartOptions.value)
      newOptions.series.push(...values(shopsData.value))
      displayChartOptions.value = newOptions

      // newOptions.chart.type = 'bar'
      // newOptions.legend = {
      //   show: true,
      //   position: 'top',
      // }
      // newOptions.plotOptions = {
      //   bar: {
      //     horizontal: false,
      //     columnWidth: '55%',
      //     endingShape: 'rounded',
      //   },
      // }

      // newOptions.chart.toolbar.tools.zoom = true
      // newOptions.xaxis.tickPlacement = 'on'
      updateChart(newOptions)
    }

    const getChart = async ({ interval, splitType }) => {
      chartParams.interval = interval
      chartParams.splitType = splitType
      const newOptions = await getFirstPurchaseData({
        orgId: orgId.value,
        shopId: shopId.value,
        type: '電商訂單',
        interval,
        splitType,
      })
      baseChartOptions.value = newOptions

      refreshChartData()
    }

    const onShopChange = async (newShops) => {
      const newShopsData = {}

      for (const shop of newShops) {
        if (get(shopsData.value, shop.id)) {
          newShopsData[shop.id] = get(shopsData.value, shop.id)
        } else {
          newShopsData[shop.id] = {
            name: shop.name,
            data: await getFirstPurchaseData({
              orgId: orgId.value,
              shopId: shop.id,
              type: '電商訂單',
              interval: chartParams.interval,
              splitType: chartParams.splitType,
            }),
          }
        }
      }

      shopsData.value = newShopsData

      refreshChartData()
    }

    return {
      getChart,
      chartRef,
      onShopChange,
      shopsData,
      baseChartOptions,
      displayChartOptions,
    }
  },
})
</script>

<style lang="postcss" scoped>
.ecommerce-statistics {
  @apply flex flex-col gap-5;
}
</style>
