<template>
  <div class="consumption-statistics">
    <ChartTitle :showBorder="false" :getChart="getChart" />
    <div class="flex justify-center">
      <DonutChartBlock
        :options="getOptions(chartData.sumAmount)"
        title="會員總消費金額數"
        :subtitle="`$ ${sumValue(chartData.sumAmount)}`"
        class="flex-1"
      />
      <DonutChartBlock
        :options="getOptions(chartData.count)"
        title="會員總消費訂單數"
        :subtitle="`${sumValue(chartData.count)}單`"
        class="flex-1"
      />
    </div>
    <div class="flex justify-center">
      <DonutChartBlock
        :options="getOptions(chartData.avgAmount)"
        title="會員平均消費金額數"
        class="flex-1"
      />
      <DonutChartBlock
        :options="getOptions(chartData.avgMemberCount)"
        title="會員平均消費訂單數"
        class="flex-1"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import DonutChartBlock from '@/components/Dashboard/DonutChartBlock.vue'
import { truncateDecimal, dataSourceLabels } from '@/utils/dashboard'
import { generateConsumptionConfig, getConsumptionData } from '../../common'
import { useOrg } from '@/use/useOrg'

export default defineComponent({
  name: 'ConsumptionStatistics',
  components: { ChartTitle, DonutChartBlock },
  setup () {
    const chartData = ref({})
    const { orgId } = useOrg()

    // const labels = [
    //   '預約訂單',
    //   '銷售紀錄',
    //   '電商訂單',
    //   '會員商城',
    //   '第三方系統',
    // ]

    const sumValue = (array = []) => {
      const res = array.reduce((prev, curr) => {
        return prev + curr
      }, 0)
      return truncateDecimal(res)
    }

    const getOptions = (series) => {
      return generateConsumptionConfig({
        labels: dataSourceLabels,
        series: series || [],
      })
    }

    const getChart = async ({ interval }) => {
      chartData.value = await getConsumptionData({
        orgId: orgId.value,
        interval,
        labels: dataSourceLabels,
      })
    }

    return {
      chartData,
      sumValue,
      getOptions,
      getChart,
    }
  },
})
</script>

<style lang="postcss" scoped>
.consumption-statistics {
  @apply flex flex-col gap-5;
}
</style>
