import dayjs from '@/lib/dayjs'
import {
  lineChartOptions,
  lineColumnChartOptions,
  donutChartOptions,
} from '@/config/dashboard'
import {
  getOrgChartData,
  getDateOfRange,
  truncateDecimal,
  getOrgShopChartData,
} from '@/utils/dashboard'

import { getCssVar } from '@/utils/helper'
import { useOrg } from '@/use/useOrg'
import { useShop } from '@/use/shop'

const dayFormat = (date, dateFormat) => {
  return dayjs(date).format(dateFormat)
}

//  會員人數趨勢default設定
export const generateMemberCountConfig = (labels = [], series = []) => ({
  ...lineColumnChartOptions,
  colors: [getCssVar('chart-1'), getCssVar('chart-2')],
  xaxis: {
    title: {
      text: '日期',
    },
  },
  yaxis: [
    {
      title: {
        text: '人數',
      },
    },
  ],
  dataLabels: {
    enabled: false,
  },
  labels,
  series,
})

//  會員人數趨勢 api資料整理
export const getMemberCountData = async ({ orgId, interval, splitType }) => {
  const dateFormat = 'YYYY/MM/DD'
  const dateRange = getDateOfRange({
    interval,
    splitType,
  })

  //  TOFIX dateTrunc檢視疑問
  const [totalMemberCountResult, newMemberCountResult] = await Promise.all([
    getOrgChartData({
      orgId,
      key: 'orgMemberCount',
      dateTrunc: 'day',
    }),
    getOrgChartData({
      orgId,
      key: 'orgNewMemberTrend',
      createdAtStart: dateRange.createdAtStart,
      createdAtEnd: dateRange.createdAtEnd,
      dateTrunc: splitType,
    }),
  ])

  let totalMemberCount = totalMemberCountResult.result[0].count

  const newMemberCount = {}
  newMemberCountResult.result.forEach((item) => {
    const createdAt = dayFormat(item.createdAt, dateFormat)
    newMemberCount[createdAt] = item.count
  })

  const columnChartData = [...dateRange.dateArray]
    .reverse()
    .map((item) => {
      const reMainCount = totalMemberCount
      totalMemberCount -= newMemberCount[item] || 0
      return reMainCount
    })
    .reverse()

  const lineChartData = dateRange.dateArray.map((item) => {
    return newMemberCount[item] || 0
  })

  return generateMemberCountConfig(dateRange.dateArray, [
    {
      name: '會員總數',
      type: 'column',
      data: columnChartData,
    },
    {
      name: '新註冊會員總數',
      type: 'line',
      data: lineChartData,
    },
  ])
}

//  首購回購default設定
export const generateFirstPurchaseConfig = (labels = [], series = []) => ({
  ...lineChartOptions,
  colors: [getCssVar('chart-1'), getCssVar('chart-2')],
  xaxis: {
    title: {
      text: '日期',
    },
  },
  yaxis: [
    {
      title: {
        text: '人數',
      },
    },
  ],
  dataLabels: {
    enabled: false,
  },
  labels,
  series,
})

//  line chart 資料解析 - 影響範圍(預約 電商 銷售 會員商城)
export const processData = (labels, data, type) => {
  const result = {}
  data
    .filter((item) => item.isRepurchase === type)
    .forEach((item) => {
      result[item.createdAt] = item.memberCount
    })
  return labels.map((item) => result[item] || 0)
}

//  首購回購api資料整理
export const getFirstPurchaseData = async ({
  orgId,
  shopId,
  type,
  interval,
  splitType,
}) => {
  const dateFormat = 'YYYY/MM/DD'
  const dateRange = getDateOfRange({
    interval,
    splitType,
  })

  const { result } = await getOrgChartData({
    orgId,
    shopId,
    key: 'orgPurchaseMemberCountTrend',
    type,
    createdAtStart: dateRange.createdAtStart,
    createdAtEnd: dateRange.createdAtEnd,
    dateTrunc: splitType,
  })

  const data = result.map((item) => ({
    ...item,
    createdAt: dayFormat(item.createdAt, dateFormat),
  }))

  const firstPurchase = processData(dateRange.dateArray, data, '首購')
  const repurchase = processData(dateRange.dateArray, data, '回購')

  return generateFirstPurchaseConfig(dateRange.dateArray, [
    {
      name: '首購',
      type: 'line',
      data: firstPurchase,
    },
    {
      name: '回購',
      type: 'line',
      data: repurchase,
    },
  ])
}

//  消費圓餅圖default設定
export const generateConsumptionConfig = ({ labels = [], series = [] }) => ({
  ...donutChartOptions,
  colors: [
    getCssVar('chart-1'),
    getCssVar('chart-2'),
    getCssVar('chart-3'),
    getCssVar('chart-4'),
    getCssVar('chart-5'),
  ],
  labels,
  series,
})

export const getConsumptionData = async ({ orgId, interval, labels }) => {
  console.log('🔥getConsumptionData', orgId)
  const dateRange = getDateOfRange({
    interval,
    splitType: 'day',
  })

  const { result } = await getOrgChartData({
    orgId,
    key: 'orgTransactionRateByType',
    dateTrunc: 'day',
    createdAtStart: dateRange.createdAtStart,
    createdAtEnd: dateRange.createdAtEnd,
  })
  console.log('🔥getConsumptionData', result)
  console.log('labels', labels)

  //  根據label生成預設資料，並且為0，後續根據api資料做累加
  const categoryChartData = {
    sumAmount: Array(labels.length).fill(0),
    count: Array(labels.length).fill(0),
    avgAmount: Array(labels.length).fill(0),
    avgMemberCount: Array(labels.length).fill(0),
  }

  result.forEach((data) => {
    const dataIndex = labels.findIndex((item) => item === data.type)
    categoryChartData.sumAmount[dataIndex] += truncateDecimal(data.sumAmount)
    categoryChartData.count[dataIndex] += truncateDecimal(data.count)
    categoryChartData.avgAmount[dataIndex] += truncateDecimal(data.avgAmount)
    categoryChartData.avgMemberCount[dataIndex] += truncateDecimal(
      data.avgMemberCount,
    )
  })
  console.log('🔥getConsumptionData', categoryChartData)
  return categoryChartData
}
